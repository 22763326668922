import HttpClient from "@/services/axios/instance";

import { catchAxiosError } from "@/services/axios/error";

import { IResponse } from "@/services/axios/entities";
import {
  ICreateCustomerForm,
  ICustomerParameters,
  IParameters,
} from "@/models/Customer";
import { IUserParameters } from "@/models/User";

const CUSTOMERS = "Customers";
const env = JSON.parse(localStorage.getItem("env") as string);
export class CustomerAPI extends HttpClient {
  constructor() {
    super(env?.VUE_APP_DOMAIN_API_SERVER);
  }

  public createCustomer = async (
    customer: ICreateCustomerForm
  ): Promise<IResponse> => {
    const response: IResponse = await this.instance
      .post(CUSTOMERS, customer)
      .catch(catchAxiosError);

    return response;
  };

  public getList = async (params?: IParameters): Promise<IResponse> => {
    const response: IResponse = await this.instance
      .get(CUSTOMERS, { params })
      .catch(catchAxiosError);

    return response;
  };

  public getUsersByCustomerId = async (
    customerId?: number,
    params?: IUserParameters
  ) => {
    const response: IResponse = await this.instance
      .get(`${CUSTOMERS}/${customerId}/users`, { params })
      .catch(catchAxiosError);

    return response;
  };

  public getSitesByCustomerId = async (customerId?: number) => {
    const response: IResponse = await this.instance
      .get(`${CUSTOMERS}/${customerId}/sites`)
      .catch(catchAxiosError);

    return response;
  };

  public getCustomerDashboard = async (params?: ICustomerParameters) => {
    const response: IResponse = await this.instance
      .get(`${CUSTOMERS}/dashboard`, { params })
      .catch(catchAxiosError);

    return response;
  };
}
