import { ISelectionDTO } from "@/models/Common";
import {
  ICreateCustomerForm,
  ICustomerDTO,
  ICustomerParameters,
  IParameters,
} from "@/models/Customer";
import { IPagination } from "@/models/Pagination";
import { IUserParameters } from "@/models/User";
import { ErrorResponse } from "@/services/axios/error";
import { CustomerAPI } from "@/services/customer";
import { UserAPI } from "@/services/user";
import store from "@/store";
import { ElMessage } from "element-plus/lib/components/message";
import isEmpty from "lodash/isEmpty";
import {
  Action,
  getModule,
  Module,
  Mutation,
  VuexModule,
} from "vuex-module-decorators";

const name = "CustomerModule";

if (store.state[name]) {
  store.unregisterModule(name);
}

@Module({ dynamic: true, name, namespaced: true, store })
class CustomerModule extends VuexModule {
  private data = [] as ICustomerDTO[];
  private siteSelection = [] as ISelectionDTO[];
  private pagination = {} as IPagination;
  private error = {} as ErrorResponse;
  private params = {} as any;

  get getSiteSelection() {
    return this.siteSelection;
  }

  get getData() {
    return this.data;
  }

  get getPagination() {
    return this.pagination;
  }

  get isError() {
    return !isEmpty(this.error);
  }

  @Mutation
  setParams(params: any) {
    this.params = params;
  }

  @Mutation
  getListSuccess(data: any) {
    if (!isEmpty(data)) {
      const { Collection, ...rest } = data;
      this.data = Collection;
      this.pagination = rest;
    }
  }

  @Mutation
  getSiteSelectionSuccess(data: ISelectionDTO[]) {
    this.siteSelection = data.map((item: ISelectionDTO) => {
      item.Selected = false;
      return item;
    });
  }

  @Mutation
  getListFailure(error: ErrorResponse) {
    this.error = error;
  }

  @Mutation
  createAccountSuccess() {
    ElMessage.success("Create new account success.");
    this.error = {} as ErrorResponse;
  }

  @Mutation
  createCustomerFailure(error: ErrorResponse) {
    this.error = error;
  }

  @Mutation
  updateUserFailure(error: ErrorResponse) {
    this.error = error;
  }

  @Mutation
  updateUserSuccess() {
    ElMessage.success("Update user account success.");
    this.error = {} as ErrorResponse;
  }

  @Mutation
  deleteUserFailure(error: ErrorResponse) {
    this.error = error;
  }

  @Mutation
  deleteUserSuccess() {
    ElMessage.success("Delete user account success.");
    this.error = {} as ErrorResponse;
  }

  @Action({ rawError: true })
  async createCustomer(customer: ICreateCustomerForm) {
    const servicesCustomerAPI = new CustomerAPI();
    const { error } = await servicesCustomerAPI.createCustomer(customer);

    if (error) {
      this.createCustomerFailure(error);
      return;
    }

    this.createAccountSuccess();
  }

  @Action({ rawError: true })
  async fetchList(params?: IParameters) {
    const servicesCustomerAPI = new CustomerAPI();
    const { data, error } = await servicesCustomerAPI.getList(params);

    if (error) {
      this.getListFailure(error);
      return;
    }

    this.getListSuccess(data);
  }

  @Action({ rawError: true })
  async fetchUserList(payload: {
    customerId: number;
    params: IUserParameters;
  }) {
    const servicesCustomerAPI = new CustomerAPI();
    const { data, error } = await servicesCustomerAPI.getUsersByCustomerId(
      payload.customerId,
      payload.params
    );

    this.setParams(payload);

    if (error) {
      this.getListFailure(error);
      return;
    }

    this.getListSuccess(data);
  }

  @Action({ rawError: true })
  async fetchSiteList(customerId: number) {
    const servicesCustomerAPI = new CustomerAPI();
    const { data, error } = await servicesCustomerAPI.getSitesByCustomerId(
      customerId
    );

    if (error) {
      this.getListFailure(error);
      return;
    }

    this.getSiteSelectionSuccess(data);
  }

  @Action({ rawError: true })
  async updateUser(user: ICustomerDTO) {
    const servicesUserAPI = new UserAPI();
    const { data, error } = await servicesUserAPI.updateUser(user);

    if (error) {
      this.updateUserFailure(error);
      return;
    }

    if (data) {
      this.fetchUserList(this.params);

      this.updateUserSuccess();
    }
  }

  @Action({ rawError: true })
  async deleteUser(item: ICustomerDTO) {
    const servicesUserAPI = new UserAPI();
    const { data, error } = await servicesUserAPI.deleteUser(item?.Id ?? -1);

    if (error) {
      this.deleteUserFailure(error);
    }

    if (data) {
      this.fetchUserList(this.params);

      this.deleteUserSuccess();
    }
  }

  @Action({ rawError: true })
  async fetchCustomerDashboard(params?: ICustomerParameters) {
    const servicesCustomerAPI = new CustomerAPI();
    const { data, error } = await servicesCustomerAPI.getCustomerDashboard(
      params
    );

    if (error) {
      this.getListFailure(error);
      return;
    }

    this.getListSuccess(data);
  }
}

export default getModule(CustomerModule);
