
import debounce from "lodash/debounce";
import { defineComponent } from "vue";
import BaseSvgIcon from "@/components/BaseSvgIcon.vue";

import CustomerModule from "@/store/modules/Customer";

export default defineComponent({
  components: {
    BaseSvgIcon,
  },
  created: function () {
    CustomerModule.fetchList(this.$route.query);
  },
  data() {
    return {
      debounce,
    };
  },
  computed: {
    tableData() {
      return CustomerModule.getData;
    },
    pagination() {
      return CustomerModule.getPagination;
    },
  },
  methods: {
    handleSizeChange(pageSize: number) {
      const query = { ...this.$route.query, pageSize };
      this.$router.replace({ query });

      CustomerModule.fetchList(query as any);
    },
    handleCurrentChange(page: number) {
      const query = { ...this.$route.query, page };
      this.$router.replace({ query });

      CustomerModule.fetchList(query);
    },
    handleSearchingAPI: debounce((e) => {
      CustomerModule.fetchList({ searchTerm: e.target.value });
    }, 500),
  },
});
